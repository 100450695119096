import * as Sentry from '@sentry/browser';
import config from './config';

if (config.env === 'production') {
  try {
    Sentry.init({
      dsn: config.sentryDSN,
      integrations: [
        Sentry.breadcrumbsIntegration({
          dom: true,
          history: true,
        }),
        Sentry.browserTracingIntegration({ traceFetch: false }),
        Sentry.captureConsoleIntegration(['error']),
        Sentry.contextLinesIntegration(),
      ],
      beforeBreadcrumb: (breadcrumb, hint) => {
        const graphQLStringQuery = (hint?.input || [])[1]?.body;
        const graphQLQuery = graphQLStringQuery && JSON.parse(graphQLStringQuery);
        const subUrl = graphQLQuery && breadcrumb.data?.url === config.graphUrl ? graphQLQuery.operationName : '';

        return {
          ...breadcrumb,
          data: {
            ...breadcrumb.data,
            url: breadcrumb.data?.url + '/' + subUrl,
          },
        };
      },
      maxBreadcrumbs: 50,
    });
  } catch (err) {
    console.log(err);
  }
}
