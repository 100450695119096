import React from 'react';

import { CardType } from 'components/creditCards/components/CreateCardModal/constants';
import { CardImage as CardImageComponent } from 'components/creditCards/components';
import { CardImageButtonProps } from './CardImageButton.types';

const CardImageButton = ({ onChange, isVirtual, isAtlas }: CardImageButtonProps) => {
  const handleSelectCard = () => onChange(isVirtual ? CardType.Virtual : CardType.Physical);

  return (
    <button type="button" className="tw-w-full tw-h-full" onClick={handleSelectCard}>
      <CardImageComponent isVirtual={isVirtual} isAtlas={isAtlas} isActive={true} />
    </button>
  );
};

export default CardImageButton;
