import React from 'react';
import cx from 'classnames';

import { CardImageProps } from './CardImage.types';
import { CreditCardStatus } from 'types/creditCard';
import PhysicalCardClean from 'components/svg/PhysicalCardClean';
import RequireActivation from 'components/svg/RequireActivation';
import AdminSuspended from 'components/svg/AdminSuspended';
import DeactivatedCard from 'components/svg/DeactivatedCard';

import creditCardVirtualSrc from 'images/creditCardVirtual.png';
import atlasCardSrc from 'images/AtlasCardMock.png';
import Frozen from 'components/svg/Frozen';
import newVisaCardSrc from 'images/newVisaCard.png';

const getCardComponent = ({ isVirtual, isAtlas, isNewVisa, isActive }: CardImageProps) => {
  const style = cx('tw-w-full tw-h-full tw-object-contain', !isActive && 'tw-opacity-60');
  switch (true) {
    case isNewVisa:
      return <img src={newVisaCardSrc} alt="Credit card" className={style} />;
    case isVirtual:
      return <img src={creditCardVirtualSrc} alt="Credit card" className={style} />;
    case isAtlas:
      return <img src={atlasCardSrc} alt="Credit card" className={style} />;
    default:
      return <PhysicalCardClean className={style} width="100%" height="100%" />;
  }
};

const getCardIcon = (status?: CreditCardStatus) => {
  switch (status) {
    case CreditCardStatus.inactive:
      return <RequireActivation height={30} width={30} />;
    case CreditCardStatus.suspended:
      return <Frozen height={30} width={30} />;
    case CreditCardStatus.admin_suspended:
      return <AdminSuspended height={30} width={30} />;
    case CreditCardStatus.lost:
    case CreditCardStatus.stolen:
    case CreditCardStatus.fraudulent:
    case CreditCardStatus.damaged:
    case CreditCardStatus.block:
      return <DeactivatedCard height={30} width={30} />;
    default:
      return null;
  }
};

const CardImage = ({ isActive, isVirtual, isAtlas, status }: CardImageProps) => {
  return (
    <div className={cx('tw-w-full tw-h-full tw-relative')}>
      {getCardComponent({ isVirtual, isAtlas, isActive })}

      <div className="tw-absolute tw-inset-0 tw-flex tw-justify-center tw-items-center">{getCardIcon(status)};</div>
    </div>
  );
};

export default CardImage;
