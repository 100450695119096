import React from 'react';
import { FormFooter, ProgressBar } from 'components/UI';

import useCardPin from './hooks/useCardPin';
import CardImage from 'components/creditCards/components/CardImage';
import { FormProvider } from 'react-hook-form';
import BasisTheoryPinInputFields from 'components/creditCards/components/BasisTheoryPinInputFields';

const CardPin = () => {
  const { currentStep, steps, form, handleSubmit, handleOnNextStep, loading, pinRef, confirmPinRef, bt } = useCardPin();

  return (
    <FormProvider {...form}>
      <form
        className="tw-w-full tw-h-full tw-flex tw-flex-col tw-items-center tw-flex-grow"
        onSubmit={handleSubmit(handleOnNextStep)}
      >
        <div className="tw-flex tw-flex-col tw-gap-4 tw-py-8 tw-w-full lg:tw-w-1/2 tw-mx-auto tw-px-4 lg:tw-px-0">
          <ProgressBar className="tw-mb-4" currentStepIndex={currentStep} stepLabelsAndIndexes={steps} />
          <div className="tw-items-start tw-h-48 tw-mx-auto">
            <CardImage isVirtual={false} isActive />
            <label className="tw-text-lg tw-font-bold tw-text-primary-dark-green tw-mt-3">Set your PIN</label>
          </div>
        </div>
        <div className="tw-mt-3">
          <BasisTheoryPinInputFields pinRef={pinRef} confirmPinRef={confirmPinRef} bt={bt} isColumnLayout={false} />
        </div>
        <div className="tw-bg-neutral-grey-4.5 tw-w-full tw-pt-4 tw-pb-8 tw-rounded-b-md tw-overflow-hidden tw-mt-auto">
          <FormFooter
            submitButtonLabel="Set Pin"
            rootClass="lg:tw-w-1/2 tw-mx-auto tw-px-4 lg:tw-px-0"
            isDisabled={loading}
          />
        </div>
      </form>
    </FormProvider>
  );
};

export default CardPin;
