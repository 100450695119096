import React, { useMemo } from 'react';
import cx from 'classnames';
import { PiCreditCard } from 'react-icons/pi';

import { getIdFromApolloGlobalId } from 'utility/apollo';
import { CreditCardStatus } from 'types/creditCard';
import { Badge } from 'components/UI';
import { BadgeStatus } from 'components/UI/Badge';
import { useIsMobile } from 'hooks';
import InitialsCirclesGroup from 'components/InitialsCirclesGroup';
import { getCardStatusDetails } from './CardsListItem.utils';
import { CardImage } from 'components/creditCards/components';
import { CardListItemProps } from './CardsListItem.types';
import CardsListItemAction from '../CardsListItemAction';
import { formatCamelToNormalCase } from 'utility/string';
const CardsListItem = ({
  cardholderName,
  cardType,
  creditCard,
  showCardholder,
  showBottomBorder,
  isCurrentUser,
  addShade,
}: CardListItemProps) => {
  const { id, contacts, status, virtual: isVirtual, lastFourDigits, displayName } = creditCard;

  const creditCardId = getIdFromApolloGlobalId({ gid: id });

  const isActive = status === CreditCardStatus.active;
  const requiresActivation = status === CreditCardStatus.inactive && !isVirtual;
  const statusDetails = getCardStatusDetails(status);
  const cardTypeDescription = formatCamelToNormalCase(cardType?.toString());
  const isMobile = useIsMobile();

  const initialsList = useMemo(
    () => contacts?.map((c) => `${c.firstName[0]}${c.lastName[0]}`.toUpperCase()) || [],
    [contacts]
  );

  return (
    <div
      className={cx(
        'tw-flex tw-flex-col tw-pt-3 tw-pb-4 lg:tw-flex-row tw-gap-1 lg:tw-gap-2 tw-px-2 tw-w-full lg:hover:tw-bg-neutral-grey-4.5 lg:tw-transition-colors lg:tw-duration-200 lg:tw-ease-in-out',
        showBottomBorder && 'tw-border-b tw-border-neutral-grey-3 lg:tw-border-neutral-grey-3.5 tw-pb-6 lg:tw-pb-3',
        addShade && 'tw-bg-neutral-grey-5'
      )}
    >
      <div className="tw-flex tw-items-center tw-w-full lg:tw-w-1/6">
        {showCardholder && (
          <div className="tw-flex tw-items-center tw-gap-2 tw-mt-2 tw-mb-4 lg:tw-my-0">
            <div className="tw-font-semibold">{cardholderName}</div>
            {isCurrentUser && <Badge status={BadgeStatus.success} title="You" />}
          </div>
        )}
      </div>
      <div className="tw-flex tw-items-center tw-w-full lg:tw-w-2/6">
        <div className="tw-h-10 tw-w-14 tw-flex-shrink-0">
          <CardImage isVirtual={isVirtual} isActive={isActive} status={status} />
        </div>

        <div className="tw-ml-4 tw-mr-4">
          <div className="tw-font-bold tw-text-sm md:tw-text-base tw-break-all">
            &bull;&bull;&nbsp;{lastFourDigits} {displayName}
          </div>
          {requiresActivation ? (
            <div className="tw-flex-none tw-w-min tw-whitespace-nowrap tw-pl-4 tw-pr-4 tw-items-center tw-text-primary-lilac tw-bg-secondary-pastel-purple-200 tw-text-sm tw-rounded-lg tw-justify-center">
              Requires Activation
            </div>
          ) : (
            <div className="tw-flex tw-space-x-4 tw-text-xs lg:tw-text-sm">
              <div className="tw-text-neutral-grey-2">{isVirtual ? 'Virtual Card' : 'Physical Card'}</div>
              <div className={`${statusDetails.className} tw-font-bold`}>{statusDetails.text}</div>
            </div>
          )}
        </div>
      </div>

      {!isMobile && (
        <>
          <div className="tw-flex tw-items-center tw-w-full lg:tw-w-1/6">
            <div className="tw-px-2">
              <InitialsCirclesGroup initialsList={initialsList} />
            </div>
          </div>
          <div className="tw-flex tw-items-center tw-w-full lg:tw-w-1/6">
            <div className="tw-flex tw-items-center tw-gap-2 tw-text-sm">
              {/* TODO: Change the icon and text based on Account Type */}
              <PiCreditCard size={20} /> <span>{cardTypeDescription}</span>
            </div>
          </div>
        </>
      )}

      <div className="tw-flex tw-items-center tw-justify-end tw-w-full lg:tw-w-1/6">
        <CardsListItemAction creditCardId={creditCardId} requiresActivation={requiresActivation} />
      </div>
    </div>
  );
};

export default CardsListItem;
